@keyframes bounce-alpha {
  0% {opacity: 1;}
  25%{opacity: 0.1;}
  26%{opacity: 0.1;}
  55% {opacity: 1;}
}

.bounce-alpha {
  animation-name: bounce-alpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

@keyframes bounce-alpha-light {
  0% {opacity: 1;}
  25%{opacity: 0.7;}
  26%{opacity: 0.7;}
  55% {opacity: 1;}
}

.bounce-alpha-light {
  animation-name: bounce-alpha-light;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.casesTable {
  min-height: 500px;
}

.callout {
  background-color: #ffffff;
}

.casePropertyMessage {
  font-size: 0.8rem;
  color: #6c757d;
}

.casePropertyPaneControls {
  padding: 15px 0 15px 0;
  max-width: 200px;
}

.actionQueue-items {
  max-height: 500px;
  padding: 3px;
  .accordion {
    .accordion-button {
      padding: 3px;
    }
    .accordion-body {
      padding: 2px;
      padding-top: 7px;
    }
  }

  .callout {
    .card-body {
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .col {
      padding: 3px;
    }
    .info-message {
      font-size: 0.8rem;
      color: #6c757d;
    }
    .done {
      color: #56b456 !important;
    }
    .error {
      color: brown !important;
    }
    margin-top: 0px;
    margin-bottom: 5px;
    padding: 0;
  }
}

.modal-header {
  height: 50px;
}
